import { createSmartAccountClient } from '../createSmartAccountClient';
import { ERC4337 } from '../../../../constants';
import { encodeExecuteAbi, encodeExecuteBatchAbi } from './abi';
import { toSimpleSmartAccount } from 'permissionless/accounts';
import { entryPoint06Address } from 'viem/account-abstraction';
export async function simpleSmartAccount(args) {
    const { owner, address, publicRpcClient } = args;
    const smartAccountImpl = {
        ...(await toSimpleSmartAccount({
            owner,
            address,
            nonceKey: 0n, // should also be 0n b/c viem's default is not?
            // 0n was original nonce for useropsjs
            index: 0n,
            client: publicRpcClient,
            entryPoint: {
                address: entryPoint06Address,
                version: '0.6',
            },
        })),
        encodeExecute: async (args) => encodeExecuteAbi(args),
        encodeExecuteBatch: async (args) => {
            const { to, data } = args;
            return encodeExecuteBatchAbi({ to, data });
        },
    };
    return createSmartAccountClient({
        ...args,
        entrypointAddress: entryPoint06Address,
        factoryAddress: ERC4337.SimpleAccount.Factory,
        smartAccountImpl,
        publicRpcClient,
    });
}
