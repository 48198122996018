import { isERC1155, isERC721 } from './tokenTypes';
import { decodeFunctionData, encodeFunctionData, isHex, parseAbi } from 'viem';
const abi = [
    'function transferFrom(address from, address to, uint256 tokenId) external',
    'function safeTransferFrom(address from, address to, uint256 id, uint256 amount, bytes data) external',
];
export async function getTransferCallData(args) {
    const { recipient, tokenId, contractAddress, client, quantity, fromAddress } = args;
    if (isNaN(Number(tokenId))) {
        throw new Error('Token ID is not a number');
    }
    try {
        if (await isERC721(contractAddress, client)) {
            return encodeFunctionData({
                abi: parseAbi(abi),
                functionName: 'transferFrom',
                args: [fromAddress, recipient, BigInt(tokenId)],
            });
        }
    }
    catch (error) {
        console.error('Error checking if ERC721', error);
    }
    try {
        const _isERC1155 = await isERC1155(contractAddress, client);
        if (_isERC1155 && !quantity) {
            throw new Error('Quantity is required for ERC1155 transfers');
        }
        else if (_isERC1155 && quantity) {
            if (isNaN(quantity)) {
                throw new Error('Quantity is not a number');
            }
            return encodeFunctionData({
                abi: parseAbi(abi),
                functionName: 'safeTransferFrom',
                args: [
                    fromAddress,
                    recipient,
                    BigInt(tokenId),
                    BigInt(quantity),
                    '0x',
                ],
            });
        }
        throw new Error('Invalid token type');
    }
    catch (error) {
        console.error('Error checking if ERC1155', error);
    }
    return undefined;
}
export function decodeTransferCallData(callData) {
    if (!isHex(callData)) {
        throw new Error('[decodeTransferCallData]::callData is not a valid hex string');
    }
    return decodeFunctionData({
        abi: parseAbi(abi),
        data: callData,
    });
}
