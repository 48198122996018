export function sumOfGas(args) {
    return args.gasLimit + args.preVerificationGas + args.verificationGasLimit;
}
export function costOfGas(args) {
    return sumOfGas(args) * args.gasPrice;
}
export function totalCostOfUserOp(args) {
    const gasCost = costOfGas(args);
    const totalCost = gasCost + (args.value ?? 0n);
    return totalCost;
}
