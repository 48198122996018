import { determineSmartAccount } from '../lib/permissionless/accounts/determineSmartAccount';
export const abstractAddressMap = new Map();
export async function getAbstractAccountAddress({ rootKeyAddress, newAccountImplementationType, paymasterProxyUrl, paymasterProxyAuthSecret, }) {
    const result = await determineSmartAccount({
        ownerAddress: rootKeyAddress,
        newAccountImplementationType,
        paymasterProxyUrl,
        paymasterProxyAuthSecret,
    });
    return result.address;
}
