import { decodeTransferCallData } from './generateTransferCallData';
import { decodeFunctionData } from 'viem';
import { decodeExecuteAbi as simpleDecodeExecuteAbi, decodeExecuteBatchAbi as simpleDecodeExecuteBatchAbi, } from '../lib/permissionless/accounts/simple/abi';
import { modularDecodeExecute, modularDecodeExecuteBatch, } from '../lib/permissionless/accounts/modular/utils';
export function decodeCallData(args) {
    const { callData, functionHash } = args;
    let executeData;
    try {
        const [_toAddress, _value, _dataBytes] = simpleDecodeExecuteAbi(callData).args;
        executeData = {
            type: 'single',
            toAddress: _toAddress,
            value: _value,
            decodedCallData: _dataBytes,
        };
    }
    catch (error) {
        try {
            const [_toAddress, _value, _dataBytes] = modularDecodeExecute(callData).args;
            executeData = {
                type: 'single',
                toAddress: _toAddress,
                value: _value,
                decodedCallData: _dataBytes,
            };
        }
        catch {
            try {
                const [_toAddress, _dataBytes] = simpleDecodeExecuteBatchAbi(callData).args;
                executeData = {
                    type: 'batch',
                    toAddress: _toAddress,
                    value: _toAddress.map(() => 0n),
                    decodedCallData: _dataBytes,
                };
            }
            catch {
                try {
                    const [args] = modularDecodeExecuteBatch(callData).args;
                    executeData = {
                        type: 'batch',
                        toAddress: args.map((arg) => arg.target),
                        value: args.map((arg) => arg.value),
                        decodedCallData: args.map((arg) => arg.data),
                    };
                }
                catch {
                    throw new Error('failed to decode call data');
                }
            }
        }
    }
    let data;
    const summedValue = executeData.type === 'single'
        ? executeData.value
        : executeData.value.reduce((acc, curr) => acc + curr, 0n);
    if (executeData.type === 'single') {
        data = {
            toAddress: executeData.toAddress,
            value: summedValue,
            executeData: executeData.decodedCallData,
            functionHash,
            executeType: 'single',
        };
    }
    else if (executeData.type === 'batch') {
        data = {
            toAddress: executeData.toAddress,
            executeData: executeData.decodedCallData,
            functionHash,
            value: summedValue,
            executeType: 'batch',
        };
    }
    else {
        throw new Error('failed to decode call data');
    }
    try {
        switch (functionHash) {
            case 'prepayMembership': {
                let supply;
                let toAddress;
                if (executeData.type !== 'single') {
                    for (let i = 0; i < executeData.decodedCallData.length; i++) {
                        try {
                            supply = decodePrepayMembership(executeData.decodedCallData[i])[0];
                            toAddress = executeData.toAddress[i];
                        }
                        catch (error) {
                            // noop
                        }
                    }
                }
                else {
                    supply = decodePrepayMembership(executeData.decodedCallData)[0];
                }
                if (supply === undefined || toAddress === undefined) {
                    break;
                }
                return {
                    toAddress,
                    value: summedValue,
                    functionHash,
                    functionData: {
                        supply,
                    },
                };
            }
            case 'transferTokens': {
                let fromAddress;
                let recipient;
                let tokenId;
                let toAddress;
                if (executeData.type !== 'single') {
                    for (let i = 0; i < executeData.decodedCallData.length; i++) {
                        try {
                            ;
                            [fromAddress, recipient, tokenId] = decodeTransferCallData(executeData.decodedCallData[i]).args;
                            toAddress = executeData.toAddress[i];
                        }
                        catch (error) {
                            // noop
                        }
                    }
                }
                else {
                    ;
                    [fromAddress, recipient, tokenId] = decodeTransferCallData(executeData.decodedCallData).args;
                    toAddress = executeData.toAddress;
                }
                if (fromAddress === undefined ||
                    recipient === undefined ||
                    tokenId === undefined ||
                    toAddress === undefined) {
                    break;
                }
                return {
                    toAddress,
                    value: summedValue,
                    functionHash,
                    functionData: {
                        fromAddress: fromAddress,
                        recipient: recipient,
                        tokenId: tokenId.toString(),
                    },
                };
            }
            case 'transferEth': {
                if (executeData.type !== 'single') {
                    for (let i = 0; i < executeData.decodedCallData.length; i++) {
                        const callData = executeData.decodedCallData[i];
                        const toAddress = executeData.toAddress[i];
                        if (callData === '0x') {
                            return {
                                toAddress,
                                value: summedValue,
                                functionHash,
                            };
                        }
                    }
                    break;
                }
                else {
                    return {
                        toAddress: executeData.toAddress,
                        functionHash,
                        value: summedValue,
                    };
                }
            }
            case 'withdraw': {
                let recipient;
                let toAddress;
                if (executeData.type !== 'single') {
                    for (let i = 0; i < executeData.decodedCallData.length; i++) {
                        try {
                            recipient = decodeWithdraw(executeData.decodedCallData[i])[0];
                            toAddress = executeData.toAddress[i];
                        }
                        catch (error) {
                            // noop
                        }
                    }
                }
                else {
                    recipient = decodeWithdraw(executeData.decodedCallData)[0];
                    toAddress = executeData.toAddress;
                }
                if (recipient === undefined || toAddress === undefined) {
                    break;
                }
                return {
                    toAddress,
                    value: summedValue,
                    functionHash,
                    functionData: {
                        recipient,
                    },
                };
            }
            default: {
                return data;
            }
        }
    }
    catch (error) {
        console.error('decodeCallData::error', error);
    }
    return data;
}
function decodePrepayMembership(_c) {
    const { args } = decodeFunctionData({
        // PrepayFacet.abi
        // defining the abi instead of passing around SpaceDapp/Space
        // alternative is to import @river-build/generated, but this is just simple and easy for now
        abi: [
            {
                type: 'function',
                name: 'prepayMembership',
                inputs: [
                    {
                        name: 'supply',
                        type: 'uint256',
                        internalType: 'uint256',
                    },
                ],
                outputs: [],
                stateMutability: 'payable',
            },
        ],
        data: _c,
    });
    return args;
}
function decodeWithdraw(_c) {
    const { args } = decodeFunctionData({
        // MembershipFacet.abi
        abi: [
            {
                type: 'function',
                name: 'withdraw',
                inputs: [
                    {
                        name: 'account',
                        type: 'address',
                        internalType: 'address',
                    },
                ],
                outputs: [],
                stateMutability: 'nonpayable',
            },
        ],
        data: _c,
    });
    return args;
}
export function isPrepayMembershipData(data) {
    if (typeof data !== 'object' || data === null) {
        return false;
    }
    return ('functionHash' in data &&
        data.functionHash === 'prepayMembership' &&
        'functionData' in data &&
        typeof data.functionData === 'object' &&
        data.functionData !== null &&
        'supply' in data.functionData &&
        'toAddress' in data &&
        'value' in data);
}
export function isTransferEthData(data) {
    if (typeof data !== 'object' || data === null) {
        return false;
    }
    return ('functionHash' in data &&
        data.functionHash === 'transferEth' &&
        'toAddress' in data &&
        'value' in data);
}
export function isTransferTokensData(data) {
    if (typeof data !== 'object' || data === null) {
        return false;
    }
    return ('functionHash' in data &&
        data.functionHash === 'transferTokens' &&
        'functionData' in data &&
        typeof data.functionData === 'object' &&
        data.functionData !== null &&
        'fromAddress' in data.functionData &&
        'recipient' in data.functionData &&
        'tokenId' in data.functionData &&
        'toAddress' in data &&
        'value' in data);
}
export function isWithdrawData(data) {
    if (typeof data !== 'object' || data === null) {
        return false;
    }
    return ('functionHash' in data &&
        data.functionHash === 'withdraw' &&
        'functionData' in data &&
        typeof data.functionData === 'object' &&
        data.functionData !== null &&
        'recipient' in data.functionData);
}
export function isBatchData(decodedCallData) {
    return (decodedCallData?.executeType === 'batch' &&
        decodedCallData?.toAddress !== undefined &&
        decodedCallData?.executeData !== undefined);
}
export function isSingleData(decodedCallData) {
    return (decodedCallData?.executeType === 'single' &&
        decodedCallData?.toAddress !== undefined &&
        decodedCallData?.executeData !== undefined);
}
