import { BASE_MAINNET, BASE_SEPOLIA } from '@towns-protocol/web3';
import { base, baseSepolia, foundry } from 'viem/chains';
export async function getChain(signer) {
    const network = await signer.provider?.getNetwork();
    if (network?.chainId === BASE_MAINNET) {
        return base;
    }
    if (network?.chainId === BASE_SEPOLIA) {
        return baseSepolia;
    }
    return foundry;
}
