import { errorCategories, errorToCodeException } from '../../../errors';
import { estimateUserOperationGas, } from 'viem/account-abstraction';
import { getAction } from 'viem/utils';
import { parseEther } from 'viem';
import { doubleGasIfLocalAnvil } from '../../../utils/anvilGasLimit';
export async function estimateGasLimit(args) {
    const { userOp, spaceId, client, spaceDapp, account } = args;
    const { sequenceName, timeTracker, stepPrefix } = args.timeTrackArgs ?? {};
    let request = userOp;
    try {
        // this is a new estimate because at this point the paymaster would have rejected our operation
        // and we need an estimate to display to the user and to submit to the bundler
        let endEstimateGas;
        if (sequenceName && timeTracker) {
            endEstimateGas = timeTracker.startMeasurement(sequenceName, `userops_${stepPrefix}_estimate_user_operation_gas`);
        }
        // If the Account has opinionated gas estimation logic, run the `estimateGas` hook and
        // fill the request with the prepared gas properties.
        if (account.userOperation?.estimateGas) {
            const gas = await account.userOperation.estimateGas(request);
            request = {
                ...request,
                ...gas,
            };
        }
        // If not all the gas properties are already populated, we will need to estimate the gas
        // to fill the gas properties.
        const estimate = await getAction(client, estimateUserOperationGas, 'estimateUserOperationGas')({
            account,
            // Some Bundlers fail if nullish gas values are provided for gas estimation :') –
            // so we will need to set a default zeroish value.
            callGasLimit: 0n,
            preVerificationGas: 0n,
            verificationGasLimit: 0n,
            stateOverride: [
                {
                    address: account.address,
                    balance: parseEther('1000000'),
                },
            ],
            ...(request.paymaster
                ? {
                    paymasterPostOpGasLimit: 0n,
                    paymasterVerificationGasLimit: 0n,
                }
                : {}),
            ...request,
        });
        if (endEstimateGas) {
            endEstimateGas?.();
        }
        return {
            ...estimate,
            callGasLimit: doubleGasIfLocalAnvil(client.chain?.id, estimate.callGasLimit),
            preVerificationGas: increaseByPercentage({
                gas: estimate.preVerificationGas,
                percentage: 10,
            }),
        };
    }
    catch (error) {
        const exception = errorToCodeException(error, errorCategories.userop_non_sponsored);
        let parsedError;
        if (args.functionHashForPaymasterProxy === 'checkIn') {
            parsedError = spaceDapp?.airdrop?.riverPoints?.parseError(exception);
        }
        // better logs
        parsedError = spaceDapp?.parseAllContractErrors({
            spaceId,
            error: exception,
        });
        console.error('[estimateGasLimit] calling estimateUserOperationGas failed:', {
            op: userOp,
            originalError: exception,
            parsedError,
        });
        throw exception;
    }
}
function increaseByPercentage({ gas, percentage }) {
    const value = gas;
    return value + (value * BigInt(percentage)) / 100n;
}
