import { getTransferCallData } from '../utils/generateTransferCallData';
import { getAbstractAccountAddress } from '../utils/getAbstractAccountAddress';
import { getSignerAddress } from '../utils/getSignerAddress';
export async function transferAssets(params) {
    const { transferData, signer, aaRpcUrl, sendUserOp, client, smartAccount, paymasterProxyUrl, paymasterProxyAuthSecret, } = params;
    const { recipient, contractAddress, tokenId, quantity } = transferData;
    const fromAddress = await getAbstractAccountAddress({
        rootKeyAddress: await getSignerAddress(signer),
        aaRpcUrl,
        newAccountImplementationType: smartAccount.type,
        paymasterProxyUrl,
        paymasterProxyAuthSecret,
    });
    if (!fromAddress) {
        throw new Error('Failed to get from address');
    }
    const callData = await getTransferCallData({
        recipient,
        tokenId,
        fromAddress,
        contractAddress,
        client,
        quantity,
    });
    return sendUserOp({
        toAddress: contractAddress,
        callData,
        signer,
        spaceId: undefined,
        functionHashForPaymasterProxy: 'transferTokens',
    });
}
