export async function refreshMetadata(params) {
    const { spaceDapp, sendUserOp, fnArgs } = params;
    const [spaceId, signer] = fnArgs;
    if (!spaceDapp) {
        throw new Error('spaceDapp is required');
    }
    const space = spaceDapp.getSpace(spaceId);
    if (!space) {
        throw new Error(`Space with spaceId "${spaceId}" is not found.`);
    }
    const callData = space.Membership.metadata.encodeFunctionData('refreshMetadata', []);
    return sendUserOp({
        toAddress: space.Address,
        callData,
        signer,
        spaceId,
        functionHashForPaymasterProxy: 'refreshMetadata',
    });
}
