import { hexToBytes, isBytes, isHex } from 'viem';
import { toAccount } from 'viem/accounts';
export async function ethersSignerToAccount(signer) {
    const signerAddress = await signer.getAddress();
    return toAccount({
        address: signerAddress,
        signMessage: async ({ message }) => {
            if (typeof message === 'string') {
                return signer.signMessage(hexToBytes(message));
            }
            if (isHex(message.raw)) {
                return signer.signMessage(hexToBytes(message.raw));
            }
            if (isBytes(message.raw)) {
                const sig = await signer.signMessage(message.raw);
                return sig;
            }
            throw new Error('Unsupported message format');
        },
        signTransaction: async (args) => {
            return signer.signTransaction(args);
        },
        signTypedData: async (args) => {
            if ('_signTypedData' in signer && typeof signer._signTypedData === 'function') {
                return signer._signTypedData(args.domain, args.types, args.message);
            }
            throw new Error('Unsupported signer type');
        },
    });
}
