import { parseAbi } from 'viem';
const abi = ['function supportsInterface(bytes4 interfaceId) external view returns (bool)'];
export async function isERC721(contractAddress, client) {
    const ERC721_INTERFACE_ID = '0x80ac58cd';
    try {
        // Call the supportsInterface function
        const result = await client.readContract({
            address: contractAddress,
            abi: parseAbi(abi),
            functionName: 'supportsInterface',
            args: [ERC721_INTERFACE_ID],
        });
        return result;
    }
    catch (error) {
        console.error('Error checking ERC721 interface:', error);
        return false;
    }
}
export async function isERC1155(contractAddress, client) {
    const ERC1155_INTERFACE_ID = '0xd9b67a26';
    try {
        // Call the supportsInterface function
        const result = await client.readContract({
            address: contractAddress,
            abi: parseAbi(abi),
            functionName: 'supportsInterface',
            args: [ERC1155_INTERFACE_ID],
        });
        return result;
    }
    catch (error) {
        console.error('Error checking ERC1155 interface:', error);
        return false;
    }
}
