import { createSmartAccountClient } from '../createSmartAccountClient';
import { ERC4337 } from '../../../../constants';
import { toModularSmartAccount } from './toModularAccount';
import { entryPoint07Address } from 'viem/account-abstraction';
export async function modularSmartAccount(args) {
    const { owner, address, publicRpcClient } = args;
    const smartAccountImpl = await toModularSmartAccount({
        owner,
        address,
        client: publicRpcClient,
    });
    return createSmartAccountClient({
        ...args,
        entrypointAddress: entryPoint07Address,
        factoryAddress: ERC4337.ModularAccount.Factory,
        smartAccountImpl,
    });
}
