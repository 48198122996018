import { userOpsStore } from './store/userOpsStore';
import { removeLink, linkEOA, linkSmartAccount, joinSpace, createSpace, createLegacySpace, updateSpaceInfo, updateChannel, createRole, legacyCreateRole, deleteRole, setChannelPermissionOverrides, clearChannelPermissionOverrides, createChannel, legacyUpdateRole, updateRole, banWallet, unbanWallet, editMembership, legacyEditMembership, prepayMembership, transferEth, withdrawSpaceFunds, transferAssets, refreshMetadata, tip, checkIn, review, upgradeToAndCall, } from './operations';
import { getAbstractAccountAddress } from './utils/getAbstractAccountAddress';
import { sendUseropWithPermissionless, } from './lib/permissionless/sendUseropWithPermissionless';
import { setupSmartAccount } from './lib/permissionless/accounts/setupSmartAccount';
import { needsUpgrade } from './utils/needsUpgrade';
export class UserOps {
    bundlerUrl;
    aaRpcUrl;
    paymasterProxyUrl;
    paymasterProxyAuthSecret;
    spaceDapp;
    timeTracker;
    fetchAccessTokenFn;
    smartAccount;
    newAccountImplementationType;
    constructor(config) {
        if (!config.newAccountImplementationType) {
            throw new Error('newAccountImplementationType is required');
        }
        this.bundlerUrl = config.bundlerUrl ?? '';
        this.aaRpcUrl = config.aaRpcUrl;
        this.paymasterProxyUrl = config.paymasterProxyUrl;
        this.paymasterProxyAuthSecret = config.paymasterProxyAuthSecret;
        this.spaceDapp = config.spaceDapp;
        this.timeTracker = config.timeTracker;
        this.fetchAccessTokenFn = config.fetchAccessTokenFn;
        this.newAccountImplementationType = config.newAccountImplementationType;
    }
    async getAbstractAccountAddress({ rootKeyAddress, }) {
        if (!this.paymasterProxyUrl || !this.paymasterProxyAuthSecret) {
            throw new Error('paymasterProxyUrl and paymasterProxyAuthSecret are required');
        }
        return getAbstractAccountAddress({
            ...this.commonParams(),
            rootKeyAddress,
            newAccountImplementationType: this.newAccountImplementationType,
        });
    }
    async sendUserOp(args, sequenceName) {
        const { functionHashForPaymasterProxy, spaceId, retryCount, signer, toAddress, callData, value, } = args;
        const timeTracker = this.timeTracker;
        let endInitClient;
        if (sequenceName) {
            endInitClient = timeTracker?.startMeasurement(sequenceName, 'userops_init_client');
        }
        let smartAccountClient = await this.getSmartAccountClient({ signer });
        endInitClient?.();
        if (!this.bundlerUrl) {
            throw new Error('bundlerUrl is required');
        }
        if (!this.aaRpcUrl) {
            throw new Error('aaRpcUrl is required');
        }
        if (!this.paymasterProxyUrl) {
            throw new Error('paymasterProxyUrl is required');
        }
        if (!this.paymasterProxyAuthSecret) {
            throw new Error('paymasterProxyAuthSecret is required');
        }
        if (!this.spaceDapp) {
            throw new Error('spaceDapp is required');
        }
        let result;
        const _needsUpgrade = needsUpgrade(this.newAccountImplementationType, smartAccountClient);
        if (_needsUpgrade &&
            ((typeof value === 'bigint' && value > 0n) ||
                (Array.isArray(value) && value.some((v) => v > 0n)))) {
            // most userops can be batched with the upgrade op.
            // but if the op is passing a value (tipping, joining a paid space, etc), we can't encodeExecuteBatch with values on a simple account, so we need to upgrade first
            const upgradeOp = await this.sendUpgradeToAndCallOp({ signer });
            const receipt = await upgradeOp.getUserOperationReceipt();
            if (!receipt) {
                throw new Error('Upgrade to waiting for receipt timed out');
            }
            if (!receipt.success) {
                throw new Error('Upgrade to failed');
            }
            this.smartAccount = undefined;
            smartAccountClient = await this.getSmartAccountClient({ signer });
        }
        if (Array.isArray(toAddress) && Array.isArray(callData)) {
            const _value = Array.isArray(value) ? value : toAddress.map(() => value ?? 0n);
            result = await sendUseropWithPermissionless({
                value: _value,
                toAddress,
                callData,
                functionHashForPaymasterProxy,
                spaceId,
                retryCount,
                smartAccountClient,
                signer,
                newAccountImplementationType: this.newAccountImplementationType,
            });
        }
        else if (typeof toAddress === 'string' && typeof callData === 'string') {
            const _value = typeof value === 'bigint' ? value : undefined;
            result = await sendUseropWithPermissionless({
                value: _value,
                toAddress,
                callData,
                functionHashForPaymasterProxy,
                spaceId,
                retryCount,
                smartAccountClient,
                signer,
                newAccountImplementationType: this.newAccountImplementationType,
            });
        }
        else {
            throw new Error('[UserOperations.sendUserOpWithPermissionless]::Invalid arguments');
        }
        if (result.didUpgrade) {
            this.smartAccount = undefined;
        }
        return result;
    }
    async getSmartAccountClient(args) {
        if (!this.smartAccount) {
            if (!this.paymasterProxyUrl) {
                throw new Error('paymasterProxyUrl is required');
            }
            if (!this.paymasterProxyAuthSecret) {
                throw new Error('paymasterProxyAuthSecret is required');
            }
            const { signer } = args;
            this.smartAccount = setupSmartAccount({
                newAccountImplementationType: this.newAccountImplementationType,
                signer: signer,
                rpcUrl: this.aaRpcUrl,
                bundlerUrl: this.bundlerUrl,
                paymasterProxyUrl: this.paymasterProxyUrl,
                paymasterProxyAuthSecret: this.paymasterProxyAuthSecret,
                spaceDapp: this.spaceDapp,
                fetchAccessTokenFn: this.fetchAccessTokenFn,
            });
        }
        return this.smartAccount;
    }
    commonParams() {
        if (!this.paymasterProxyUrl || !this.paymasterProxyAuthSecret) {
            throw new Error('paymasterProxyUrl and paymasterProxyAuthSecret are required');
        }
        return {
            spaceDapp: this.spaceDapp,
            timeTracker: this.timeTracker,
            aaRpcUrl: this.aaRpcUrl,
            paymasterProxyUrl: this.paymasterProxyUrl,
            paymasterProxyAuthSecret: this.paymasterProxyAuthSecret,
            sendUserOp: this.sendUserOp.bind(this),
        };
    }
    clearStore(sender) {
        if (sender) {
            userOpsStore.getState().reset(sender);
        }
    }
    // public async dropAndReplace(hash: string, signer: ethers.Signer) {
    //     const sender = (await this.getSmartAccountClient({ signer })).address
    //     const userOpState = selectUserOpsByAddress(sender, userOpsStore.getState())
    //     if (!userOpState) {
    //         throw new Error('current user op not found')
    //     }
    //     const pending = userOpState.pending
    //     if (!pending.op?.callData) {
    //         throw new Error('user op call data not found')
    //     }
    //     if (pending.hash !== hash) {
    //         throw new Error('user op hash does not match')
    //     }
    //     if (!pending.functionHashForPaymasterProxy) {
    //         throw new Error('user op function hash for paymaster proxy not found')
    //     }
    //     const pendingDecodedCallData = decodeCallData({
    //         callData: pending.op.callData,
    //         functionHash: pending.functionHashForPaymasterProxy,
    //     })
    //     const spaceId = pending.spaceId
    //     const functionHashForPaymasterProxy = pending.functionHashForPaymasterProxy
    //     if (isBatchData(pendingDecodedCallData)) {
    //         const { toAddress, value, executeData } = pendingDecodedCallData
    //         return this.sendUserOp({
    //             toAddress,
    //             callData: executeData,
    //             signer,
    //             spaceId,
    //             functionHashForPaymasterProxy,
    //             value,
    //         })
    //     } else if (isSingleData(pendingDecodedCallData)) {
    //         const { toAddress, value, executeData } = pendingDecodedCallData
    //         return this.sendUserOp({
    //             toAddress,
    //             callData: executeData,
    //             signer,
    //             spaceId,
    //             functionHashForPaymasterProxy,
    //             value,
    //         })
    //     } else {
    //         throw new Error('mismatch in format of toAddress and callData')
    //     }
    // }
    async sendUpgradeToAndCallOp(args) {
        const smartAccountClient = await this.getSmartAccountClient({ signer: args.signer });
        return upgradeToAndCall({
            ...this.commonParams(),
            smartAccountClient,
            signer: args.signer,
        });
    }
    async sendCreateLegacySpaceOp(args) {
        const smartAccountClient = await this.getSmartAccountClient({ signer: args[1] });
        return createLegacySpace({
            ...this.commonParams(),
            factoryAddress: smartAccountClient.factoryAddress,
            entryPointAddress: smartAccountClient.entrypointAddress,
            fnArgs: args,
            smartAccount: smartAccountClient,
        });
    }
    async sendCreateSpaceOp(args) {
        const smartAccountClient = await this.getSmartAccountClient({ signer: args[1] });
        return createSpace({
            ...this.commonParams(),
            factoryAddress: smartAccountClient.factoryAddress,
            entryPointAddress: smartAccountClient.entrypointAddress,
            fnArgs: args,
            smartAccount: smartAccountClient,
        });
    }
    /**
     * Join a space, potentially linking a wallet if necessary
     */
    async sendJoinSpaceOp(args) {
        const smartAccountClient = await this.getSmartAccountClient({ signer: args[2] });
        return joinSpace({
            ...this.commonParams(),
            factoryAddress: smartAccountClient.factoryAddress,
            entryPointAddress: smartAccountClient.entrypointAddress,
            fnArgs: args,
            smartAccount: smartAccountClient,
            newAccountImplementationType: this.newAccountImplementationType,
        });
    }
    /**
     * User operation to link smart account wallet to the root key.
     * @param args
     */
    async sendLinkSmartAccountToRootKeyOp(rootKeySigner, abstractAccountAddress, sequenceName) {
        return linkSmartAccount({
            ...this.commonParams(),
            rootKeySigner,
            abstractAccountAddress,
            sequenceName,
        });
    }
    /**
     * User operation to link an EOA (NOT smart account) wallet to the root key.
     *
     * @param args
     * @returns
     */
    async sendLinkEOAToRootKeyOp(args) {
        return linkEOA({
            ...this.commonParams(),
            fnArgs: args,
        });
    }
    async sendRemoveWalletLinkOp(args) {
        return removeLink({
            ...this.commonParams(),
            fnArgs: args,
        });
    }
    async sendUpdateSpaceInfoOp(args) {
        return updateSpaceInfo({
            ...this.commonParams(),
            fnArgs: args,
        });
    }
    async sendCreateChannelOp(args) {
        return createChannel({
            ...this.commonParams(),
            fnArgs: args,
        });
    }
    async sendUpdateChannelOp(args) {
        return updateChannel({
            ...this.commonParams(),
            fnArgs: args,
        });
    }
    async sendLegacyCreateRoleOp(args) {
        return legacyCreateRole({
            ...this.commonParams(),
            fnArgs: args,
        });
    }
    async sendCreateRoleOp(args) {
        return createRole({
            ...this.commonParams(),
            fnArgs: args,
        });
    }
    async sendDeleteRoleOp(args) {
        return deleteRole({
            ...this.commonParams(),
            fnArgs: args,
        });
    }
    async sendUpdateRoleOp(args) {
        return updateRole({
            ...this.commonParams(),
            fnArgs: args,
        });
    }
    async sendSetChannelPermissionOverridesOp(args) {
        return setChannelPermissionOverrides({
            ...this.commonParams(),
            fnArgs: args,
        });
    }
    async sendClearChannelPermissionOverridesOp(args) {
        return clearChannelPermissionOverrides({
            ...this.commonParams(),
            fnArgs: args,
        });
    }
    async sendLegacyUpdateRoleOp(args) {
        return legacyUpdateRole({
            ...this.commonParams(),
            fnArgs: args,
        });
    }
    async sendBanWalletAddressOp(args) {
        return banWallet({
            ...this.commonParams(),
            fnArgs: args,
        });
    }
    async sendUnbanWalletAddressOp(args) {
        return unbanWallet({
            ...this.commonParams(),
            fnArgs: args,
        });
    }
    async sendEditMembershipSettingsOp(args) {
        return editMembership({
            ...this.commonParams(),
            spaceId: args.spaceId,
            updateRoleParams: args.updateRoleParams,
            membershipParams: args.membershipParams,
            signer: args.signer,
        });
    }
    async sendLegacyEditMembershipSettingsOp(args) {
        return legacyEditMembership({
            ...this.commonParams(),
            spaceId: args.spaceId,
            legacyUpdateRoleParams: args.legacyUpdateRoleParams,
            membershipParams: args.membershipParams,
            signer: args.signer,
        });
    }
    async sendPrepayMembershipOp(args) {
        return prepayMembership({
            ...this.commonParams(),
            fnArgs: args,
        });
    }
    async sendTransferEthOp(transferData, signer) {
        const smartAccountClient = await this.getSmartAccountClient({ signer });
        return transferEth({
            ...this.commonParams(),
            transferData,
            signer,
            factoryAddress: smartAccountClient.factoryAddress,
            entryPointAddress: smartAccountClient.entrypointAddress,
            smartAccount: smartAccountClient,
        });
    }
    async sendWithdrawSpaceFundsOp(args) {
        return withdrawSpaceFunds({
            ...this.commonParams(),
            fnArgs: args,
        });
    }
    async sendTransferAssetsOp(transferData, signer) {
        const smartAccountClient = await this.getSmartAccountClient({ signer });
        return transferAssets({
            ...this.commonParams(),
            transferData,
            signer,
            client: smartAccountClient.publicRpcClient,
            smartAccount: smartAccountClient,
        });
    }
    async refreshMetadata(args) {
        return refreshMetadata({
            ...this.commonParams(),
            fnArgs: args,
        });
    }
    async sendTipOp(args) {
        return tip({
            ...this.commonParams(),
            fnArgs: args,
        });
    }
    async sendCheckInOp(args) {
        return checkIn({
            ...this.commonParams(),
            fnArgs: args,
        });
    }
    async sendReviewOp(args) {
        return review({
            ...this.commonParams(),
            fnArgs: args,
        });
    }
    async sendTokenTransferOperationWithCallData(args) {
        if (Array.isArray(args.callData) && Array.isArray(args.toAddress)) {
            // TODO: trading maybe wants to use this?
            const value = Array.isArray(args.value) ? args.value : args.toAddress.map(() => 0n);
            return this.sendUserOp({
                callData: args.callData,
                toAddress: args.toAddress,
                value,
                signer: args.signer,
                spaceId: undefined,
                functionHashForPaymasterProxy: 'trading',
            });
        }
        else if (typeof args.callData === 'string' &&
            typeof args.toAddress === 'string' &&
            typeof args.value === 'bigint') {
            return this.sendUserOp({
                callData: args.callData,
                toAddress: args.toAddress,
                value: args.value,
                signer: args.signer,
                spaceId: undefined,
                functionHashForPaymasterProxy: 'trading',
            });
        }
        throw new Error('Invalid arguments');
    }
    /**
     * Collectively these calls can take > 1s
     * So optionally you can call this method to prep the builder and userOpClient prior to sending the first user operation
     */
    async setup(signer) {
        return Promise.all([this.getSmartAccountClient({ signer })]);
    }
    async reset() {
        const sender = (await this.smartAccount)?.address;
        this.smartAccount = undefined;
        this.clearStore(sender);
    }
}
