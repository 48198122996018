import { getFunctionSigHash } from '../utils/getFunctionSigHash';
export async function linkEOA(params) {
    const { spaceDapp, sendUserOp, fnArgs } = params;
    if (!spaceDapp) {
        throw new Error('spaceDapp is required');
    }
    const [signer, externalWalletSigner] = fnArgs;
    const walletLink = spaceDapp.walletLink;
    const functionName = 'linkWalletToRootKey';
    const functionHashForPaymasterProxy = getFunctionSigHash(walletLink.getInterface(), functionName);
    const callDataForLinkingWallet = await spaceDapp.walletLink.encodeLinkWalletToRootKey(signer, externalWalletSigner);
    return sendUserOp({
        toAddress: walletLink.address,
        callData: callDataForLinkingWallet,
        signer,
        spaceId: undefined,
        functionHashForPaymasterProxy,
    });
}
