import { BigNumber } from 'ethers';
import { bytesToHex, isBytes, isHex } from 'viem';
export const migrations = {
    1: (state) => {
        const newUserOps = Object.fromEntries(Object.entries(state.userOps).map(([sender, oldState]) => {
            return [
                sender,
                {
                    operationAttempt: oldState.operationAttempt ?? 1,
                    rejectedSponsorshipReason: oldState.rejectedSponsorshipReason,
                    retryDetails: oldState.retryDetails
                        ? {
                            type: oldState.retryDetails.type,
                            data: oldState.retryDetails.data,
                        }
                        : undefined,
                    sequenceName: oldState.sequenceName,
                    current: {
                        op: oldState.currOp,
                        value: oldState.currOpValue,
                        decodedCallData: oldState.currOpDecodedCallData,
                        functionHashForPaymasterProxy: oldState.functionHashForPaymasterProxy,
                        spaceId: oldState.spaceId,
                    },
                    pending: {
                        op: undefined,
                        value: undefined,
                        decodedCallData: undefined,
                        hash: undefined,
                        functionHashForPaymasterProxy: undefined,
                        spaceId: undefined,
                    },
                },
            ];
        }));
        return {
            userOps: newUserOps,
        };
    },
    2: (state) => {
        const newUserOps = Object.fromEntries(Object.entries(state.userOps).map(([sender, oldState]) => {
            const oldCurrentOp = oldState.current;
            const oldPendingOp = oldState.pending;
            return [
                sender,
                {
                    ...oldState,
                    current: {
                        spaceId: oldCurrentOp.spaceId,
                        op: oldCurrentOp.op
                            ? v2OpToV3Op(oldCurrentOp.op)
                            : undefined,
                        functionHashForPaymasterProxy: oldCurrentOp.functionHashForPaymasterProxy,
                    },
                    pending: {
                        spaceId: oldPendingOp.spaceId,
                        op: oldPendingOp.op
                            ? v2OpToV3Op(oldPendingOp.op)
                            : undefined,
                        hash: oldPendingOp.hash,
                        functionHashForPaymasterProxy: oldPendingOp.functionHashForPaymasterProxy,
                    },
                },
            ];
        }));
        return {
            userOps: newUserOps,
        };
    },
};
const bytesLikeToHex = (bytes) => {
    if (isBytes(bytes)) {
        return bytesToHex(bytes);
    }
    if (isHex(bytes)) {
        return bytes;
    }
    throw new Error('Invalid bytes');
};
const v2OpToV3Op = (op) => {
    return {
        sender: op.sender,
        callData: bytesLikeToHex(op.callData),
        callGasLimit: BigNumber.from(op.callGasLimit).toBigInt(),
        maxFeePerGas: BigNumber.from(op.maxFeePerGas).toBigInt(),
        maxPriorityFeePerGas: BigNumber.from(op.maxPriorityFeePerGas).toBigInt(),
        nonce: BigNumber.from(op.nonce).toBigInt(),
        preVerificationGas: BigNumber.from(op.preVerificationGas).toBigInt(),
        verificationGasLimit: BigNumber.from(op.verificationGasLimit).toBigInt(),
        signature: bytesLikeToHex(op.signature),
        initCode: bytesLikeToHex(op.initCode),
        paymasterAndData: bytesLikeToHex(op.paymasterAndData),
    };
};
