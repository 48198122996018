import { PaymasterErrorCode } from '../../../types';
import { z } from 'zod';
import { CodeException } from '../../../errors';
import { isUsingAlchemyBundler } from '../../../utils/isUsingAlchemyBundler';
import { selectUserOpsByAddress, userOpsStore } from '../../../store/userOpsStore';
import { estimateGasFeesWithReplacement } from './estimateGasFees';
import { formatUserOperationRequest, } from 'viem/account-abstraction';
import { hexToBigInt, isHex, toHex } from 'viem';
import { getPrivyLoginMethodFromLocalStorage } from '../../../utils/privyLoginMethod';
import { NON_SPONSORED_LOGIN_METHODS } from '../../../constants';
import { NON_SPONSORED_FUNCTION_HASHES } from '../../../constants';
import { decodeCallData } from '../../../utils/decodeCallData';
const zAddress = z.custom((val) => typeof val === 'string' && isHex(val));
// Create Zod schemas based on the TypeScript types
const zPaymaster06Response = z.object({
    paymasterAndData: zAddress,
    preVerificationGas: zAddress,
    verificationGasLimit: zAddress,
    callGasLimit: zAddress,
    maxPriorityFeePerGas: zAddress.optional(),
    maxFeePerGas: zAddress.optional(),
});
const zPaymaster07Response = z.object({
    preVerificationGas: zAddress,
    callGasLimit: zAddress,
    paymasterVerificationGasLimit: zAddress,
    paymasterPostOpGasLimit: zAddress,
    verificationGasLimit: zAddress,
    paymaster: zAddress,
    paymasterData: zAddress,
    maxPriorityFeePerGas: zAddress.optional(),
    maxFeePerGas: zAddress.optional(),
});
const zSuccessSchema = z.object({
    data: z.union([zPaymaster06Response, zPaymaster07Response]),
});
const zErrorSchema = z.object({
    errorDetail: z.object({
        code: z.nativeEnum(PaymasterErrorCode),
    }),
});
export const paymasterProxyMiddleware = async (args) => {
    const { rootKeyAddress, paymasterProxyAuthSecret, paymasterProxyUrl, fetchAccessTokenFn, bundlerUrl, } = args;
    const { current, pending } = selectUserOpsByAddress(args.userOp.sender);
    const pendingHash = pending.hash;
    const { functionHashForPaymasterProxy, spaceId } = current;
    if (functionHashForPaymasterProxy &&
        NON_SPONSORED_FUNCTION_HASHES.includes(functionHashForPaymasterProxy)) {
        return;
    }
    const value = args.userOp.callData
        ? decodeCallData({
            callData: args.userOp.callData,
            functionHash: functionHashForPaymasterProxy,
        })?.value
        : undefined;
    if (value !== undefined && (value < 0n || value > 0n)) {
        return;
    }
    const loginMethod = getPrivyLoginMethodFromLocalStorage();
    if (loginMethod && NON_SPONSORED_LOGIN_METHODS.includes(loginMethod)) {
        return;
    }
    let maxFeePerGasOverride;
    let maxPriorityFeePerGasOverride;
    if (pendingHash && args.userOp.sender) {
        // get the gas values
        const result = await estimateGasFeesWithReplacement({
            sender: args.userOp.sender,
            client: args.client,
        });
        maxFeePerGasOverride = result.maxFeePerGas;
        maxPriorityFeePerGasOverride = result.maxPriorityFeePerGas;
    }
    try {
        if (!functionHashForPaymasterProxy) {
            throw new Error('functionHashForPaymasterProxy is required');
        }
        const doNotRequireSpaceId = [
            'createSpace',
            'createSpaceWithPrepay',
            'createSpace_linkWallet',
            'linkWalletToRootKey',
            'linkCallerToRootKey',
            'removeLink',
            'transferTokens',
            'withdraw',
            'upgradeToAndCall',
        ];
        if (!spaceId && !doNotRequireSpaceId.includes(functionHashForPaymasterProxy)) {
            const errorMessage = `[paymasterProxyMiddleware] spaceId is required for all user operations except ${doNotRequireSpaceId.join(', ')}`;
            console.error(errorMessage);
            throw new Error(errorMessage);
        }
        const op = args.userOp;
        if (!op.callData ||
            op.preVerificationGas === undefined ||
            !op.sender ||
            !op.signature ||
            op.nonce === undefined ||
            op.callGasLimit === undefined ||
            op.verificationGasLimit === undefined ||
            op.maxFeePerGas === undefined ||
            op.maxPriorityFeePerGas === undefined) {
            throw new Error('Invalid user operation');
        }
        const rpcRequest = {
            ...formatUserOperationRequest(op),
            functionHash: functionHashForPaymasterProxy,
            rootKeyAddress,
            townId: spaceId,
            gasOverrides: {
                maxFeePerGas: maxFeePerGasOverride ? toHex(maxFeePerGasOverride) : undefined,
                maxPriorityFeePerGas: maxPriorityFeePerGasOverride
                    ? toHex(maxPriorityFeePerGasOverride)
                    : undefined,
            },
        };
        let sponsorUserOpUrl = `${paymasterProxyUrl}/api/sponsor-userop`;
        if (isUsingAlchemyBundler(bundlerUrl)) {
            sponsorUserOpUrl = `${paymasterProxyUrl}/api/sponsor-userop/alchemy`;
        }
        let accessToken;
        try {
            accessToken = (await fetchAccessTokenFn?.()) ?? undefined;
        }
        catch (error) {
            throw new CodeException({
                message: 'Failed to get access token',
                code: 'USER_OPS_FAILED_ACCESS_TOKEN',
                data: error,
                category: 'userop',
            });
        }
        const response = await fetch(sponsorUserOpUrl, {
            method: 'POST',
            body: JSON.stringify({
                data: rpcRequest,
            }),
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${paymasterProxyAuthSecret}`,
                'X-PM-Token': accessToken ?? '',
            },
        });
        const json = await response.json();
        if (!response.ok) {
            const errorParseResult = zErrorSchema.safeParse(json);
            if (errorParseResult.success) {
                userOpsStore
                    .getState()
                    .setRejectedSponsorshipReason(op.sender, errorParseResult.data.errorDetail.code);
            }
            else {
                console.log('[paymasterProxyMiddleware] errorParseResult.error, error may be missing in PaymasterErrorCode');
            }
            throw new Error(`[paymasterProxyMiddleware] Error getting paymaster proxy response:: ${response.status} ${response.statusText} ${JSON.stringify(json)}`);
        }
        const parseResult = zSuccessSchema.safeParse(json);
        if (!parseResult.success) {
            throw new Error(`[paymasterProxyMiddleware] Error parsing PaymasterProxyResponse:: ${JSON.stringify(parseResult.error)}`);
        }
        const parsedData = parseResult.data.data;
        const gas = {
            preVerificationGas: hexToBigInt(parsedData.preVerificationGas),
            verificationGasLimit: hexToBigInt(parsedData.verificationGasLimit),
            callGasLimit: hexToBigInt(parsedData.callGasLimit),
            paymasterAndData: undefined,
            paymasterData: undefined,
            paymaster: undefined,
            paymasterVerificationGasLimit: undefined,
            paymasterPostOpGasLimit: undefined,
        };
        //////////////////////////////////////////////////////////////////
        // Add 06 fields
        //////////////////////////////////////////////////////////////////
        if ('paymasterAndData' in parsedData) {
            gas.paymasterAndData = parsedData.paymasterAndData;
        }
        //////////////////////////////////////////////////////////////////
        // Add 07 fields
        //////////////////////////////////////////////////////////////////
        if ('paymaster' in parsedData) {
            gas.paymaster = parsedData.paymaster;
        }
        if ('paymasterData' in parsedData) {
            gas.paymasterData = parsedData.paymasterData;
        }
        if ('paymasterVerificationGasLimit' in parsedData) {
            gas.paymasterVerificationGasLimit = hexToBigInt(parsedData.paymasterVerificationGasLimit);
        }
        if ('paymasterPostOpGasLimit' in parsedData) {
            gas.paymasterPostOpGasLimit = hexToBigInt(parsedData.paymasterPostOpGasLimit);
        }
        //////////////////////////////////////////////////////////////////
        // Add alchemy fields
        //////////////////////////////////////////////////////////////////
        if (parsedData.maxFeePerGas && parsedData.maxPriorityFeePerGas) {
            // alchemy returns these as well, so we need to set them here
            // https://docs.alchemy.com/reference/alchemy-requestgasandpaymasteranddata
            return {
                ...gas,
                maxFeePerGas: parsedData.maxFeePerGas
                    ? hexToBigInt(parsedData.maxFeePerGas)
                    : undefined,
                maxPriorityFeePerGas: parsedData.maxPriorityFeePerGas
                    ? hexToBigInt(parsedData.maxPriorityFeePerGas)
                    : undefined,
            };
        }
        return gas;
    }
    catch (error) {
        console.error('[paymasterProxyMiddleware] error', error);
    }
};
