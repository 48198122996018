/**
 * should return a matching functionHash for paymaster proxy validation
 * TODO: proxy still uses function name, not sigHash
 */
export function getFunctionSigHash(_contractInterface, functionHash) {
    return functionHash;
    // TODO: swap to this
    // const frag = contractInterface.getFunction(functionName)
    // return frag.format() // format sigHash
}
