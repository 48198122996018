import { BigNumber } from 'ethers';
import { getAbstractAccountAddress } from '../utils/getAbstractAccountAddress';
import { getSignerAddress } from '../utils/getSignerAddress';
export async function transferEth(params) {
    const { transferData, signer, aaRpcUrl, sendUserOp, smartAccount, paymasterProxyUrl, paymasterProxyAuthSecret, } = params;
    const { recipient, value } = transferData;
    const aaAddress = await getAbstractAccountAddress({
        rootKeyAddress: await getSignerAddress(signer),
        aaRpcUrl,
        newAccountImplementationType: smartAccount.type,
        paymasterProxyUrl,
        paymasterProxyAuthSecret,
    });
    if (!aaAddress) {
        throw new Error('Failed to get AA address');
    }
    console.log('[UserOperations] sendTransferEthOp', {
        toAddress: recipient,
        callData: '0x',
        functionHashForPaymasterProxy: 'transferEth',
        spaceId: undefined,
        value,
    });
    return sendUserOp({
        toAddress: recipient,
        callData: '0x',
        functionHashForPaymasterProxy: 'transferEth',
        signer,
        spaceId: undefined,
        value: BigNumber.from(value).toBigInt(),
    });
}
