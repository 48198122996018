import { isUserOpResponse } from './isUserOpResponse';
export function getTransactionHashOrUserOpHash(tx) {
    if (!tx) {
        return;
    }
    if (isUserOpResponse(tx)) {
        return tx.userOpHash;
    }
    return tx.hash;
}
/**
 * In the case of a user op, wait for the user op to be sent and return the correct transaction hash that a provider can wait for
 */
export async function getTransactionHashFromTransactionOrUserOp(tx) {
    if (isUserOpResponse(tx)) {
        const response = await tx.wait();
        return response?.transactionHash;
    }
    return tx?.hash;
}
