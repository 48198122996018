import { ethers } from 'ethers';
var SpaceReviewAction;
(function (SpaceReviewAction) {
    SpaceReviewAction[SpaceReviewAction["Add"] = 0] = "Add";
    SpaceReviewAction[SpaceReviewAction["Update"] = 1] = "Update";
    SpaceReviewAction[SpaceReviewAction["Delete"] = 2] = "Delete";
})(SpaceReviewAction || (SpaceReviewAction = {}));
export async function review(params) {
    const { spaceDapp, sendUserOp, fnArgs } = params;
    const [{ spaceId, rating, comment, isUpdate, isDelete }, signer] = fnArgs;
    const space = spaceDapp?.getSpace(spaceId);
    if (!space) {
        throw new Error(`Space with spaceId "${spaceId}" is not found.`);
    }
    let callData;
    if (isDelete) {
        // For delete, we don't need to encode any review data
        callData = space.Review.encodeFunctionData('setReview', [
            SpaceReviewAction.Delete,
            ethers.utils.defaultAbiCoder.encode(['string'], ['']),
        ]);
    }
    else {
        const encodedData = ethers.utils.defaultAbiCoder.encode(['tuple(string,uint8)'], [[comment, rating]]);
        callData = space.Review.encodeFunctionData('setReview', [
            isUpdate ? SpaceReviewAction.Update : SpaceReviewAction.Add,
            encodedData,
        ]);
    }
    return sendUserOp({
        toAddress: space.Address,
        callData,
        signer,
        spaceId,
        functionHashForPaymasterProxy: 'unsponsored',
    });
}
