import { decodeFunctionData, encodeFunctionData } from 'viem';
export const executeBatchAbi = [
    {
        inputs: [
            {
                internalType: 'address[]',
                name: 'dest',
                type: 'address[]',
            },
            {
                internalType: 'bytes[]',
                name: 'func',
                type: 'bytes[]',
            },
        ],
        name: 'executeBatch',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function',
    },
];
export const executeAbi = [
    {
        inputs: [
            {
                internalType: 'address',
                name: 'dest',
                type: 'address',
            },
            {
                internalType: 'uint256',
                name: 'value',
                type: 'uint256',
            },
            {
                internalType: 'bytes',
                name: 'func',
                type: 'bytes',
            },
        ],
        name: 'execute',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function',
    },
];
export const encodeExecuteAbi = (args) => encodeFunctionData({
    abi: executeAbi,
    functionName: 'execute',
    args: [args.to, args.value, args.data],
});
export const encodeExecuteBatchAbi = (args) => encodeFunctionData({
    abi: executeBatchAbi,
    functionName: 'executeBatch',
    args: [args.to, args.data],
});
export const decodeExecuteBatchAbi = (data) => decodeFunctionData({
    abi: executeBatchAbi,
    data,
});
export const decodeExecuteAbi = (data) => decodeFunctionData({
    abi: executeAbi,
    data,
});
export const createAccountAbi = [
    {
        inputs: [
            {
                internalType: 'address',
                name: 'owner',
                type: 'address',
            },
            {
                internalType: 'uint256',
                name: 'salt',
                type: 'uint256',
            },
        ],
        name: 'createAccount',
        outputs: [
            {
                internalType: 'contract SimpleAccount',
                name: 'ret',
                type: 'address',
            },
        ],
        stateMutability: 'nonpayable',
        type: 'function',
    },
];
export const upgradeAbi = [
    {
        inputs: [{ internalType: 'address', name: 'newImplementation', type: 'address' }],
        name: 'upgradeTo',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function',
    },
    {
        inputs: [
            { internalType: 'address', name: 'newImplementation', type: 'address' },
            { internalType: 'bytes', name: 'data', type: 'bytes' },
        ],
        name: 'upgradeToAndCall',
        outputs: [],
        stateMutability: 'payable',
        type: 'function',
    },
];
export const ownerAbi = [
    {
        inputs: [],
        name: 'owner',
        outputs: [{ internalType: 'address', name: '', type: 'address' }],
        stateMutability: 'view',
        type: 'function',
    },
];
