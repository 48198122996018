export const FunctionHash = {
    // createSpace
    createSpace: 'createSpace',
    createSpaceWithPrepay: 'createSpaceWithPrepay',
    //
    removeLink: 'removeLink',
    joinSpace: 'joinSpace',
    linkCallerToRootKey: 'linkCallerToRootKey',
    // RoleBase.sol
    createRole: 'createRole',
    removeRole: 'removeRole',
    updateRole: 'updateRole',
    // ChannelBase.sol
    createChannel: 'createChannel',
    createChannelWithOverridePermissions: 'createChannelWithOverridePermissions',
    updateChannel: 'updateChannel',
    removeChannel: 'removeChannel',
    addRoleToChannel: 'addRoleToChannel',
    removeRoleFromChannel: 'removeRoleFromChannel',
    // EntitlementsManagerBase.sol
    removeEntitlementModule: 'removeEntitlementModule',
    addEntitlementModule: 'addEntitlementModule',
    // SpaceOwnerBase.sol
    updateSpaceInfo: 'updateSpaceInfo',
    // WalletLink.sol
    linkWalletToRootKey: 'linkWalletToRootKey',
    // Banning.sol
    ban: 'ban',
    unban: 'unban',
    createSpace_linkWallet: 'createSpace_linkWallet',
    joinSpace_linkWallet: 'joinSpace_linkWallet',
    prepayMembership: 'prepayMembership',
    editMembershipSettings: 'editMembershipSettings',
    setChannelPermissionOverrides: 'setChannelPermissionOverrides',
    clearChannelPermissionOverrides: 'clearChannelPermissionOverrides',
    unsupported: 'unsupported',
    transferTokens: 'transferTokens',
    transferEth: 'transferEth',
    refreshMetadata: 'refreshMetadata',
    withdraw: 'withdraw',
    // ITipping.sol
    tip: 'tip',
    checkIn: 'checkIn',
    trading: 'trading',
    // unsponsored user ops
    unsponsored: 'unsponsored',
    upgradeToAndCall: 'upgradeToAndCall',
};
export const TimeTrackerEvents = {
    CREATE_SPACE: 'CREATE_SPACE',
    JOIN_SPACE: 'JOIN_SPACE',
};
export var PaymasterErrorCode;
(function (PaymasterErrorCode) {
    PaymasterErrorCode["PAYMASTER_LIMIT_REACHED"] = "PAYMASTER_LIMIT_REACHED";
    PaymasterErrorCode["DAILY_LIMIT_REACHED"] = "DAILY_LIMIT_REACHED";
})(PaymasterErrorCode || (PaymasterErrorCode = {}));
